.heading {
  color: #8F3D1F !important;
}

.fs-7 {
  font-size: 0.75rem!important;
}

.required:after {
  content: "*";
  color: red;
}

.icon {
  height: 1rem;
  width: auto;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.animation {
  transition-timing-function: ease-out;
  transition: 0.2s;
}